<template>
  <div class="template">
    <nav-logged v-if="$vuetify.breakpoint.mdAndUp"></nav-logged>
    <div class="user page bg-loop-js " :class="{'mobile': $vuetify.breakpoint.smAndDown, 'stopped' : createMeeting === true}">
      <v-container fill-height fluid class="align-start align-md-center pb-13">

        <v-row align="start" justify="center" align-md="center" style="z-index:0" class="content mx-0 fill-height" no-gutters>
          <v-col cols="12" md="10" lg="8">
            <div class="white elevation-10"
              no-gutters
              style="overflow:hidden"
              :class="{'rounded-lg' : $vuetify.breakpoint.mdAndUp}"
              v-if="!$isEmpty(loadedUser)">

              <!-- Mobile App -->
              <template v-if="$vuetify.breakpoint.smAndDown">
                <user-mobile :loadedUser="loadedUser" :myProfile="myProfile" :createMeeting="createMeeting" @create-proposal="createMeetingProposal"></user-mobile>
              </template>
              <!-- Desktop -->
              <template v-else>
                <v-row no-gutters class="bg-loop">
                  <v-col cols="3" class="pa-3">
                    <div class="profile-photo pt-10 pt-md-0">
                      <template v-if="!$isEmpty(loadedUser.data.photoURL)">
                        <img :src="loadedUser.data.photoURL.cropped" alt="" class="profile-img mb-2 rounded elevation-6">
                      </template>
                      <template v-else>
                        <!-- <img :src="loadedUser.data.basicAvatar" alt="" class="profile-img mb-2 rounded elevation-6"> -->
                        <v-avatar size="60" color="white" class="headline black--text">
                          {{loadedUser.data.firstName.charAt(0)}}
                        </v-avatar>
                      </template>
                    </div>
                  </v-col>
                  <v-col>
                    <h2>{{loadedUser.data.firstName}} {{loadedUser.data.lastName}}</h2>
                    <!-- <div v-resize-text="{ratio:1}" class="resize-text mb-4">{{loadedUser.data.firstName}} {{loadedUser.data.lastName}}</div> -->
                  </v-col>
                </v-row>
                <div class="pa-md-4">
                    <v-row align="center" justify="center" justify-md="space-between" class="mb-2 text-center text-md-left">
                      <v-col md="6" cols="12">
                        <div class="text-h5">Hey, I'm {{loadedUser.data.firstName}}</div>
                      </v-col>
                      <v-col md="6" cols="auto" class="d-flex pt-4 py-md-0 " v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <template v-if="true">
                          <v-btn color="primary"
                          class="mx-xs-auto meet-me bg-loop"
                          depressed
                          @click="createMeetingProposal"
                          v-if="!myProfile"
                          :class="{'stopped' : createMeeting === true}"
                          >
                            Meet with Me <span class="px-2" style="opacity:.5">|</span><strong>${{loadedUser.data.rate}}</strong>
                          </v-btn>
                        </template>
                      </v-col>
                    </v-row>

                    <p class="text-center text-md-left" style="white-space: pre-line;">{{loadedUser.data.description}}</p>
                    <v-divider class=" my-5"></v-divider>
                    <h1 class="text-h5">Let's chat about:</h1>
                    <v-chip
                      v-for="skill in loadedUser.data.skills" :key="skill.name"
                      class="ma-2 pl-0"
                      label

                      color="grey lighten-3"
                      text-color="black"
                      >

                    <v-avatar tile left class="grey lighten-4 pl-4 pr-2 grey--text">{{skill.meetings || 0}}</v-avatar>
                    {{skill.name}}
                    <template v-if="skill.rating != null">
                      <v-icon right v-if="skill.rating > 3" color="green">mdi-menu-up</v-icon>
                      <v-icon right v-if="skill.rating < 3" color="red">mdi-menu-down</v-icon>
                    </template>
                  </v-chip>

                </div>
              </template>
            </div>

          </v-col>

          <!-- <v-col cols="12">
            <v-btn color="success" @click="addCalendar()">Add Calendar</v-btn>
            <iframe style="width:100%; height:70vh" :src="calAuthUrl" v-if="calAuthUrl" class="d-block"></iframe>
          </v-col> -->
        </v-row>

        <create-meeting :loadedUser="loadedUser" :localUser="localUser" @close-meeting="closeModal" v-if="createMeeting === true"></create-meeting>

  </v-container>
</div>
    <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    dark
    >
    {{ snackbar.message }}
    <v-btn
    text
    color="white"
    @click="snackbar.show = false"
    >
    Close
    </v-btn>
    </v-snackbar>
    <nav-logged-mobile v-if="$vuetify.breakpoint.smAndDown"></nav-logged-mobile>
</div>
</template>

<script>

const navLogged = () => import("@/components/shared/nav-logged.vue");
const navLoggedMobile = () => import("@/components/shared/nav-logged-mobile.vue");
const userMobile = () => import("@/components/mobile/user.vue");
const createMeeting = () => import("@/components/shared/create-meeting.vue");

import { cloud, storage, db } from '@/firebase';
import { mapGetters } from 'vuex'

export default {
  name: "user",
  components:{
    navLogged, navLoggedMobile, userMobile, createMeeting
  },
  computed:{
     getPercentages(arr){
        return true;
    },
    // userId: function (){ return this.$store.state.userId},
    proposals: function (){ return this.$store.state.proposals},
  },
  watch:{
    userName: function(val){
      if(val !== null){
        this.init(val)
      }
    }
  },
  data(){
    return{
      isLoading:true,
      userName: null,
      gradient:"",
      calAuthUrl:false,
      isLoaded: false,
      myProfile: false,
      createMeeting:false,
      showNewAccount: false,
      profileChecker: "",
      loadedUser:[],
      localUser:[],
      showAvatar:false,
      avatarType: false,
      preferAvatar: false,
      displayName: "",
      photoURL: "",
      snackbar:{
        show:false,
      },
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },
  methods:{
    init(userName){
      let vThis = this;
      vThis.userName = userName;
      process.env.NODE_ENV === "development" ? console.log( userName ) : null;
      this.loadUser(userName).then(x => this.getLocalUser()).then( result =>{
        process.env.NODE_ENV === "development" ? console.log( 'loaded everything', vThis.userName ) : null;
      })
    },
    async getLocalUser(){
      let vThis = this;
      let localUser = this.$store.dispatch('getCurrentUser').then(results =>{
        if(!vThis.$isEmpty(results) || !vThis.$isUndefined(results)){
          // set local User
          vThis.localUser = results;
          vThis.myProfile = vThis.userName === results.data.userName ? true : false;
        }
      }).catch(error =>{

      })

    },
    async loadUser(userName){
      let vThis = this;
      vThis.isLoading = true;
      process.env.NODE_ENV === "development" ? console.log( 'LOAD USER FUNC', userName) : null;
      let loadedUser = db.collection('users').where('userName', '==', userName).get().then((result)=>{
        if (result.empty) {
          console.log('No matching documents.');
          return;
        }

        result.forEach(doc => {
          console.log(doc.id, '=>', doc.data());
          let data = {
            userId: doc.id,
            data: doc.data()
          }
          vThis.loadedUser = data;
          vThis.isLoading = false;
        });
      })
      return loadedUser
    },
    siteIcon(url){
      let website = url.replace(/.+\/\/|www.|\..+/g, '');
      if(website == 'facebook'){ return 'mdi-facebook'}
      if(website == 'linkedin'){ return 'mdi-linkedin'}
      if(website == 'instagram'){ return 'mdi-facebook'}
      else{ return 'mdi-web'}
    },
    closeModal(){
      this.createMeeting = false;
    },
    createMeetingProposal(){
      let vThis = this;
      vThis.createMeeting = true;
    },
    isAlreadyProposed(){
      let prop = this.$store.getters.isAlreadyProposed(this.loadedUser.userId)
      process.env.NODE_ENV === "development" ? console.log( 'already proposed', prop) : null;
    },
  },
  mounted(){
    process.env.NODE_ENV === "development" ? console.log( 'this.$router.params.userName', this.$route.params ) : null;
    this.init(this.$route.params.userName);

    // this.gradient = new this.$gradient.default(
    //   '.bg-loop-js',
    //   [
    //     "linear-gradient(60deg, rgb(255, 0, 0), rgb(0, 0, 255))", // Array of CSS gradients
    //     "linear-gradient(10deg, rgb(25, 123, 23), #ff22af)",
    //     "radial-gradient(rgb(25, 123, 223), red)"
    //   ],
    //   10000 // Fading interval in miliseconds
    // )
  },
  beforeRouteUpdate (to, from, next) {
    process.env.NODE_ENV === "development" ? console.log( 'to, from, next', to.params.userName, from.params.userName ) : null;
    this.userName = to.params.userName;
    next();
    // next(vm => {
    //   alert('things')
    //   vm.loadUser(to.params.userName)
    // })
  }
};
</script>


<style lang="scss">
.user.page{
  background-color: #f2f2f2;
  position: relative;
  // align-items: center;
  // display: flex;
  &:before {
      content: "";
      background-image: url("~@/assets/meetable-tile2.png");
      background-repeat: repeat;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.6;
      z-index: 0;
    }
  .profile-photo{
    .profile-img{
      max-width: 100%;
      border-radius: 6px;
      // border: 3px solid white;
    }
    h2{
      line-height: 95%;
    }
    .company-links{
      text-decoration: none;
    }
  }
  .meet-me.v-btn{
    padding: 12px 16px;
    height:auto;
    > span{
      font-size: 16px!important;
      text-shadow: 1px 1px 3px rgba(0,0,0,.4);
    }
  }
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }

  .resize-text{
    // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }


  &.mobile{
    // padding-top:0;
    // padding-left: 0;
    // align-items:start;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .content{
      min-height: 90vh;
    }
    .profile-details{
      .profile-img{
        max-width: 80vw;
        // border-radius: 0;
        // border: none;
      }
    }
    .meet-me.v-btn{
      // margin-top: -20px;
      border-radius: 6px;
      // text-shadow: 1px 1px 5px rgba(0,0,0,.60);
      background-color: rgba(0,0,0,.5)!important;
      border: 3px solid white!important;
    }
  }
}

</style>
